import { doc, getFirestore, setDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const editPendingDocument = async (screenshot, editedData) => {
  const firestore = getFirestore();

  const { userId, id } = screenshot;

  if (id) {
    const audienceEstimateScreenshotsRef = doc(
      firestore,
      Collections.PodcastUsers,
      userId,
      Collections.AudienceEstimateScreenshots,
      id
    );

    await setDoc(
      audienceEstimateScreenshotsRef,
      {
        ...editedData,
      },
      { merge: true }
    );
  } else {
    const userReference = doc(firestore, Collections.PodcastUsers, userId);

    await setDoc(
      userReference,
      {
        podcastData: { ...editedData },
      },
      { merge: true }
    );
  }
};

export default editPendingDocument;
